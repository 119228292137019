var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.pageDecorImage ? 'bg-no-repeat bg-right-bottom bg-contain' : '',style:(_vm.backgroundImageStyle)},[(_vm.isLoading)?_c('Spinners',{staticClass:"h-screen mt-40"}):(_vm.sessions.length != 0)?_c('div',{key:"has-sessions",staticClass:"container session-schedule pb-12"},[_c('div',{staticClass:"flex overflow-x-auto whitespace-no-wrap md:mb-6 button-tabs",class:{
                'justify-center': _vm.lessTabsThanTabsContainerWidth
            },attrs:{"id":"button-tab-container"}},_vm._l((_vm.tabsSchedule),function(tab){return _c('button',{key:tab,staticClass:"rounded-t-xl session-tab inline-block mr-1 outline-none py-4 px-8 mt-16 ",class:[
                    {
                        'font-bold': _vm.selected === tab
                    },
                    {
                        border: _vm.selected === tab
                    },
                    {
                        'border-b-0': _vm.selected === tab
                    },
                    {
                        'is-active': _vm.selected === tab
                    }
                ],on:{"click":function($event){return _vm.changeDate(tab)}}},[_vm._v(" "+_vm._s(_vm.MgFormatISODateTime(tab, "day-month"))+" ")])}),0),_c('AdSlot',{attrs:{"slot-name":"sessions"}}),_c('div',[_c('div',{staticClass:"flex justify-center items-center py-8 md:mb-8"},[_c('svg-calendar',{staticClass:"text-2xl md:text-5xl mr-6"}),_c('h2',{staticClass:"text-xl md:text-2xl mb-0"},[_vm._v(" "+_vm._s(_vm.MgFormatISODateTime(this.selected, "fullday-month"))+" ")])],1),_c('div',{staticClass:"uppercase text-xl font-bold mb-2"},[_c('span',{staticClass:"inline-block w-1/5 md:pl-4"},[_vm._v("Time")]),_c('span',{staticClass:"md:pl-4"},[_vm._v("SESSION NAME")])]),_vm._l((_vm.sortedSession),function(schedule){return _c('div',{key:schedule.sessionId,staticClass:"flex flex-col lg:flex-row border-b border-dividers-dark last:border-0"},[_c('div',{staticClass:"lg:w-1/4",class:_vm.cssVariables['--schedule-dayTime-bg-color']
                            ? 'bg-dayTime-schedule-label'
                            : 'bg-secondary'},[_c('div',{staticClass:"h-full flex p-4 items-start text-xl min-w-full table-head-cell"},[_c('div',{staticClass:"w-full items-center grid"},[_c('svg-clock',{staticClass:"mb-2 text-2xl m-auto"}),_c('div',{staticClass:"m-auto text-md flex flex-col items-start"},[_c('span',[_vm._v(_vm._s(_vm.parseTimeToISO( ("" + (schedule[0].startTime)), ("" + (schedule[0].endTime)) ))+" ")]),_c('span',{},[_vm._v(_vm._s(("(" + _vm.detectUserTimeZone + ")"))+" ")])])],1)])]),_c('div',{staticClass:"flex flex-col w-full",class:_vm.cssVariables[
                            '--schedule-contentDescription-bg-color'
                        ]
                            ? 'bg-schedule-contentDescription'
                            : 'bg-white'},_vm._l((schedule),function(session){return _c('SessionScheduleItem',{key:("" + (session.sessionId)),staticClass:"px-4 border-b border-dividers last:border-0",attrs:{"session":session,"isLoadingFavorites":_vm.isLoadingFavorites,"isOndemandData":_vm.isOndemandData}})}),1)])})],2)],1):_c('div',{key:"no-sessions",staticClass:"container py-40 flex justify-center"},[_c('span',{staticClass:"notification is-danger max-w-md"},[_c('strong',[_vm._v("Sorry")]),_vm._v(", no Schedule available yet. Please check back later. ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }